import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FacturationService } from '../../services/facturation.service';
import { INettingSchemaMontant, INettingWithMontant } from 'src/app/shared/models/netting-account';
import { CalendrierService } from '../../../calendrier-pack/calendrier.service';
import { ICalculatedNetting } from 'src/app/shared/models/calculated_netting';
import * as fileSaver from 'file-saver';
import { ApplicationSettingsService } from '../../../application-settings/application-settings.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { MatTab } from '@angular/material/tabs';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-facturation-netting-list',
  templateUrl: './facturation-netting-list.component.html',
  styleUrls: ['./facturation-netting-list.component.less'],
})
export class FacturationNettingListComponent implements OnInit {
  public yearSelected!: number;
  public months_list: string[] = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  public monthSelected!: string;
  public packSelected!: number;

  public dateSelected!: string;
  public vueSelected!: string;
  public sequenceSelected!: number;

  public nettingSource: INettingWithMontant[] = [];
  public nettingSourceMemory: INettingWithMontant[] = [];
  public displayedColumns_netting: string[] = ['code_netting', 'montant'];
  public CA_facture: { montant: number }[] = [];
  public CA_facture_schema = new MatTableDataSource<INettingSchemaMontant>();
  public displayedColumns_CA: string[] = ['CA_facture'];
  public displayedColumns_CA_by_schema = ['schema_name', 'montant'];
  public nettingCalculatedSource: ICalculatedNetting[] = [];
  public headerGen: string[] = [
    'Sequence',
    'Projet',
    'Nature imputation',
    'Compte Imputé',
    'Intitulé',
  ];
  public keyGen: string[] = [
    'sequence',
    'projet',
    'nature',
    'compte_impute',
    'intitule',
    'montant',
  ];
  public displayedColumnsGenKeys!: string[];
  public displayedColumnsGen!: Array<any>;

  public totalMontant!: number;
  public montantdiffzero!: number;
  public ecart!: number;

  public tableReadOnly = false;
  public isGenererDisabled = true;
  public isFormDisabled!: boolean;
  public sequenceDisabled = true;
  public NetRegisShown!: boolean;
  public NetGenisShown!: boolean;
  public displaySchemaManquant!: boolean;

  public totalMontantSchema: number = 0;


  constructor(
    public _facturationService: FacturationService,
    public theme: ThemeService,
    public _calendrierService: CalendrierService,
    private cdRef: ChangeDetectorRef,
    private applicationSettingsService: ApplicationSettingsService,
    private snackbarService: SnackbarService
  ) {

  }

  ngOnInit(): void {
    this.displaySchemaManquant = false;
    this.NetRegisShown = false;
    this.NetGenisShown = false;
  }



  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  updatePackYearSelected(packSelected: number): void {
    this.packSelected = packSelected;
  }

  updateYearSelected(yearSelected: number): void {
    this.yearSelected = yearSelected;
  }

  updateMonthSelected(monthSelected: string): void {
    this.monthSelected = monthSelected;
  }

  updateVueSelected(vueSelected: string): void {
    this.vueSelected = vueSelected;
  }

  makeTableEditable(): void {
    if (this.vueSelected == 'Netting mois') {
      this.tableReadOnly = true;
    }
    if (this.vueSelected == 'Regularisation') {
      this.tableReadOnly = false;
    }
  }

  updateNetRegisShown(NetRegisShown: boolean): void {
    this.NetRegisShown = NetRegisShown;
    this.isFormDisabled = true;
    this.sequenceDisabled = false;
    this.getNettingWithCostService();
  }

  getCAService(): void {
    const yearFac: string = this.yearSelected.toString();
    const yearPack: string = this.packSelected.toString();
    const monthFac: string = this.monthSelected;
    this._facturationService
      .getNettingCA(yearPack, yearFac, monthFac)
      .subscribe({
        next: (data) => {
          if (data) {
            this.CA_facture = [{ montant: data }];
            this.ecartService();
            if (this.CA_facture[0].montant != undefined) {
              this.CA_facture[0].montant =
                Math.round(this.CA_facture[0].montant * 100) / 100;
            }
          } else {
            this.CA_facture = [{ montant: 0 }];
            this.ecartService();
          }
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar('Erreur lors de la récupération des données par compte de netting');
        },
        complete: () => { },
      });
    this._facturationService.getNettingCABySchema(yearPack, yearFac, monthFac).subscribe({
      next: (data) => {
        data = data.filter((schema) => !schema.schema_name.includes('GLOBAL'));
        this.CA_facture_schema = new MatTableDataSource<INettingSchemaMontant>(data);
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar('Erreur lors de la récupération des données par schema de netting');
      },
      complete: () => {
        this.calculateTotalMontantSchema();
      },
    });


  }

  changeCA(CA: number): void {
    this.ecartService();
  }

  ecartService(): void {
    if (this.CA_facture[0].montant != undefined) {
      this.ecart = this.CA_facture[0].montant - this.totalMontant;
      this.ecart = Math.round(this.ecart * 100) / 100;
    }
    if (this.ecart == 0) {
      document.getElementById('ecart')!.style.border = '2px solid green';
    } else if (this.ecart > 0 && this.ecart < 0.1) {
      document.getElementById('ecart')!.style.border = '2px solid orange';
    } else {
      document.getElementById('ecart')!.style.border = '2px solid red';
    }
  }

  LineIsRed(schema: string): boolean {
    if (schema == '0') {
      this.displaySchemaManquant = true;
      return true;
    } else {
      return false;
    }
  }

  LineIsOrange(schema: any): boolean {
    if (!schema) {
      return false;
    }
    if (schema.includes('GLOBAL')) {
      return true;
    } else {
      return false;
    }
  }

  CellIsRed(element: any, header: string): boolean {
    if (!this.headerGen.includes(header)) {
      return element < 0;
    } else {
      return false;
    }
  }

  deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map((item) => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce(
            (o, prop) => {
              Object.defineProperty(
                o,
                prop,
                Object.getOwnPropertyDescriptor(source, prop)!,
              );
              o[prop] = this.deepCopy(
                (source as { [key: string]: any })[prop],
              );
              return o;
            },
            Object.create(Object.getPrototypeOf(source)),
          )
          : (source as T);
  }

  getNettingWithCostService(): void {
    const year: string = this.yearSelected.toString();
    const month: string = this.monthSelected;
    const pack: string = this.packSelected.toString();

    this._facturationService
      .getNettingNamesWithCost(pack, year, month)
      .subscribe({
        next: (data) => {
          if (data) {
            this.totalMontant = 0;
            this.montantdiffzero = 0;
            for (let i = 0; i < data.length; i++) {
              if (this.vueSelected == 'Netting mois') {
                if (data[i].schema_id != '0') {
                  this.totalMontant += data[i].montant;
                  data[i].montant = Math.round(data[i].montant * 100) / 100;
                }
                if (data[i].montant != 0) {
                  this.montantdiffzero = 1;
                }
              } else {
                data[i].montant = 0;
                this.CA_facture = [{ montant: 0 }];
                this.ecartService();
              }
            }
            this.makeTableEditable();
            this.checkGenerer();
            this.nettingSource = data;
            this.nettingSourceMemory = this.deepCopy(data);
          } else {
            window.alert('Aucun CA trouvé pour ce mois');
          }
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar('Erreur lors de la récupération des données');
        },
        complete: () => {
          this.getCAService();
        },
      });
  }

  changeMontant(element: any): void {
    this.montantdiffzero = 0;
    for (let i = 0; i < this.nettingSourceMemory.length; i++) {
      if (
        this.nettingSourceMemory[i].name == element.name &&
        element.schema_id != '0'
      ) {
        this.totalMontant -= this.nettingSourceMemory[i].montant;
        this.totalMontant += element.montant;
        this.nettingSourceMemory[i].montant = element.montant;
      } else if (this.nettingSourceMemory[i].name == element.name) {
        this.nettingSourceMemory[i].montant = element.montant;
      }
      if (this.nettingSourceMemory[i].montant != 0) {
        this.montantdiffzero = 1;
      }
    }
    this.ecartService();
    this.checkGenerer();
  }

  changeSequence(sequence: number): void {
    this.sequenceSelected = sequence;
    this.checkGenerer();
  }

  abandonService(): void {
    this.NetRegisShown = false;
    this.CA_facture = [];
    this.ecart = 0;
    this.displaySchemaManquant = false;
    this.nettingSource = [];
    this.sequenceSelected = {} as number;
    this.sequenceDisabled = true;
    this.isGenererDisabled = true;
    this.isFormDisabled = false;
  }

  checkGenerer(): void {
    if (Number.isInteger(this.sequenceSelected) && this.montantdiffzero > 0) {
      this.isGenererDisabled = false;
    } else {
      this.isGenererDisabled = true;
    }
  }

  genererService(): void {
    this.NetRegisShown = false;
    this.NetGenisShown = true;
    this.tableDefGenNet();
    this.calculatedNetting();
  }

  tableDefGenNet(): void {
    this.dateSelected =
      this.months_list[+this.monthSelected - 1] +
      '-' +
      this.yearSelected.toString();
    const headerGenCopy: string[] = this.deepCopy(this.headerGen);
    headerGenCopy.push(this.dateSelected);

    this.displayedColumnsGen = [
      {
        key: this.keyGen[0],
        header: headerGenCopy[0],
      },
    ];

    for (let i = 1; i < headerGenCopy.length; i++) {
      this.displayedColumnsGen.push({
        key: this.keyGen[i],
        header: headerGenCopy[i],
      });
    }
    this.displayedColumnsGenKeys = this.displayedColumnsGen.map(
      (col) => col.key,
    );
  }

  calculatedNetting(): void {
    this._facturationService
      .getCalculatedNetting(
        this.packSelected,
        this.yearSelected,
        this.monthSelected,
        this.sequenceSelected,
        this.nettingSource,
      )
      .subscribe({
        next: (data) => {
          if (data) {
            this.nettingCalculatedSource = data;
          }
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar('Erreur lors de la récupération des données');
        },
        complete: () => { },
      });
  }

  retourService(): void {
    this.NetRegisShown = true;
    this.NetGenisShown = false;
  }

  exportService(): void {
    this._facturationService
      .exportNettingCalculated(this.nettingCalculatedSource, this.dateSelected)
      .subscribe({
        next: (data) => {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          fileSaver.saveAs(
            blob,
            'Netting' +
            this.yearSelected +
            ' - ' +
            this.monthSelected +
            '-' +
            this.yearSelected +
            ' - ' +
            this.applicationSettingsService.applicationSettings.CDS_name +
            ' - ' +
            new Date().toLocaleDateString().replace('_', '-') +
            ' ' +
            new Date().toLocaleTimeString().replace('_', '-'),
          );
          this.openSuccessSnackBar('Export réussi');
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar('Erreur lors de l\'export');
        },
        complete: () => { },
      });
  }

  getRowBackgroundColor(sequence: number): string {
    return sequence % 2 === 0 ? 'lightgray' : 'white';
  }

  calculateTotalMontantSchema(): void {
    this.totalMontantSchema = this.CA_facture_schema.data.reduce((acc, schema) => acc + schema.montant, 0);
  }

  compareCaSchema(): boolean {
    if (this.CA_facture && this.CA_facture.length > 0 && this.totalMontantSchema != this.CA_facture[0].montant) {
        return true;
    } else {
      return false;
    } 
  }
}
