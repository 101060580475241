<mat-spinner *ngIf="spinner"></mat-spinner>
<div class="imported-invoices" [ngClass]="{ spinner: spinner }">
  <div class="section-container" id="leftSection-nav">
    <mat-table class="light mat-elevation-z4 left" [dataSource]="dataSummary" id="excludTable">
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="exclure">
          Exclure</mat-header-cell>
        <mat-cell *matCellDef="let element" class="exclure">
          <mat-checkbox (change)="exculdeDemand(element.demandReference)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="demandNumber">
        <mat-header-cell *matHeaderCellDef> Commande</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onSelectDemand(element.demandReference)">
          {{ element.demandReference }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="demandInvoice">
        <mat-header-cell *matHeaderCellDef> A facturer</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.demandAmount | number: "1.1-2" : "fr-FR" }} €</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" class="row focusable"
        [ngClass]="{ red: isExceeded(row) }"></mat-row>

      <!-- No data row -->
      <ng-template matNoDataRow>
        <div class="no-data-row">
          ✅ Il n'y a aucun bon de commande à facturer.
        </div>
      </ng-template>
    </mat-table>
  </div>
  <div class="section-container" id="rightSection-nav">
    <div class="headerContainer">
      <div class="moisFacturation">
        <mat-form-field appearance="outline">
          <mat-label>Mois de facturation</mat-label>
          <mat-select [(ngModel)]="monthSelected" (selectionChange)="changeMonth($event.value)">
            <mat-option *ngFor="let month of months" [value]="month">{{
              month
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="anneePack">
        <mat-form-field appearance="outline">
          <mat-label>Année PACK</mat-label>
          <mat-select [(ngModel)]="yearPackSelected" (selectionChange)="changePackYear($event.value)">
            <mat-option *ngFor="let year of years" [value]="year">{{
              year
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="totalAFacturer">
        <div id="total_invoices label">
          <b>Total à facturer </b>:
          {{ totalFacture | number: "1.1-2" : "fr-FR" }} €
        </div>
      </div>
      <div class="validationFacturation">
        <button mat-raised-button class="indigo input large-button" id="btnVerificationValidation"
          [style.background-color]="theme.getColor('Main Light')" type="submit" [disabled]="isButtonDisabled"
          [ngClass]="isButtonDisabled ? 'btnDisabled' : ''" (click)="
            exportPreparationNetting(
              yearPackSelected,
              selectedYear,
              currentMonth,
              demandsExcluded,
              (validation = false)
            )
          ">
          Verifier la facturation
        </button>
        <button mat-raised-button class="indigo input large-button" id="btnConfirmationValidation"
          [style.background-color]="theme.getColor('Main Dark')" type="submit" [disabled]="isButtonDisabled"
          [ngClass]="isButtonDisabled ? 'btnDisabled' : ''" (click)="
            exportPreparationNetting(
              yearPackSelected,
              selectedYear,
              currentMonth,
              demandsExcluded,
              (validation = true)
            )
          ">
          Valider la facturation
        </button>
      </div>
    </div>
    <div id="rightTable">
      <mat-table class="light mat-elevation-z4 right" [dataSource]="dataImported" id="factList">
        <ng-container matColumnDef="demandId">
          <mat-header-cell *matHeaderCellDef class="ref">
            Référence commande</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.demandReference }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="demandNumber">
          <mat-header-cell *matHeaderCellDef> N° demande</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.demandNumber }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="demandCost">
          <mat-header-cell *matHeaderCellDef>
            Montant demande avant réduction</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.demandCost | number: "1.1-2" : "fr-FR" }} €</mat-cell>
        </ng-container>

        <ng-container matColumnDef="demandDescription">
          <mat-header-cell *matHeaderCellDef> Désignation</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.demandDescription }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="demandApp">
          <mat-header-cell *matHeaderCellDef> Application</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.demandApplicationName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="demandService">
          <mat-header-cell *matHeaderCellDef> Service appelé</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.demandServiceType }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="demandType">
          <mat-header-cell *matHeaderCellDef>
            Type de prestation</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.demandPrestationType }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="demandInvoice">
          <mat-header-cell *matHeaderCellDef> A facturer</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.echeanceCost | number: "1.1-2" : "fr-FR" }} €</mat-cell>
        </ng-container>

        <ng-container matColumnDef="netting">
          <mat-header-cell *matHeaderCellDef> Netting</mat-header-cell>
          <mat-cell *matCellDef="let element" id="{{ element.demandNumber }}">
            {{ element.demandNetting }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div>
              <button mat-icon-button aria-label="edit" (click)="
                  dialogChangeNetting(
                    element.demandNumber,
                    element.demandReference
                  )
                ">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsData; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsData" class="striked {{ row.demandReference }} focusable"
          [ngClass]="{ red: isRetarded(row) }"></mat-row>

        <!-- No data row -->
        <ng-template matNoDataRow>
          <div class="no-data-row">✅ Il n'y a aucune demande à facturer</div>
        </ng-template>
      </mat-table>
    </div>
  </div>
</div>