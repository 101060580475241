<form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline" color="accent" class="input-space">
    <mat-label>Nom</mat-label>
    <input matInput required formControlName="name" type="text" [readonly]="asEdited">
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent" class="input-space input-width">
    <mat-label>Ligne</mat-label>
    <input matInput required formControlName="line" type="number" [readonly]="asEdited">
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent" class="input-space">
    <mat-label>Nature Imputation</mat-label>
    <mat-select required formControlName="nature" [disabled]="asEdited">
      <mat-option *ngFor="let nature of natures" [value]="nature">{{ nature }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent" class="input-space">
    <mat-label>Compte imputé</mat-label>
    <mat-select formControlName="na_name" (selectionChange)="onCompteChange($event)" [disabled]="asEdited">
      <mat-option *ngFor="let compte of compte_imputes" [value]="compte.na_name">{{ compte.na_name}} </mat-option>
    </mat-select>
  </mat-form-field>

  <input type="hidden" formControlName="compte_imputes_id">

  <mat-form-field appearance="outline" color="accent" class="input-space input-width">
    <mat-label>Ligne Ref</mat-label>
    <input matInput required formControlName="line_ref" type="number" [readonly]="asEdited">
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent" class="input-space input-width">
    <mat-label>1er Taux</mat-label>
    <input matInput required formControlName="taux1" type="number" [readonly]="asEdited">
    <mat-hint>Obligatoire</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent" class="input-space input-width">
    <mat-label>Année pivot</mat-label>
    <input matInput formControlName="pivotal_year" type="number" [readonly]="asEdited">
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent" class="input-space input-width">
    <mat-label>2nd Taux</mat-label>
    <input matInput formControlName="taux2" type="number" [readonly]="asEdited">
  </mat-form-field>

  <div class="form_btn">
    <button mat-raised-button class="btn_created" color="primary" type="submit" [disabled]="!formGroup.valid"
      *ngIf="!asEdited && !btnStatus">Créer
    </button>
    <button mat-raised-button class="btn_annuler" color="accent" type="button" (click)="clear()">Annuler</button>
    <button mat-raised-button class="btn_valid" type="submit" [disabled]="!formGroup.valid"
      *ngIf="!asEdited && btnStatus">Valider
    </button>
    <button mat-raised-button class="btn_edit" *ngIf="asEdited" (click)="is_edit(asEdited)">Editer</button>
    <button mat-raised-button class="btn_supp" color="warn" *ngIf="asEdited" (click)="confirmDelete()">Supprimer
    </button>
  </div>
</form>