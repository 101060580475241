import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {IInvoiceDemands, IInvoiceOrderSummary, IInvoiceSummary} from "src/app/shared/models/invoices";
import {INetting, INettingSchemaMontant, INettingWithMontant} from "src/app/shared/models/netting-account";
import {INettingPack} from "src/app/shared/models/netting-pack";
import {UtilsService} from "src/app/shared/services/utils.service";
import {ICalculatedNetting} from "src/app/shared/models/calculated_netting";

import {environment} from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class FacturationService {
  public options = {
    headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
  };

  constructor(private http: HttpClient, private utilsService: UtilsService) {

  }

  uploadInvoiceFile(file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(environment.backendApiUrl + "/invoice", formData, {responseType: 'text'}).pipe(catchError(this.utilsService.handleError));
  }

  getInvoiceSummary(year: number): Observable<IInvoiceSummary[]> {
    return this.http.get<IInvoiceSummary[]>(environment.backendApiUrl + "/invoice/summary?year=" + year, this.options).pipe(catchError(this.utilsService.handleError));
  }

  exportInvoice(year: number): Observable<any> {
    return this.http.get(environment.backendApiUrl + "/invoice/export?year=" + year, {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

  getLastClosedMonth(): Observable<any> {
    return this.http.get<IInvoiceSummary[]>(environment.backendApiUrl + "/invoice/lastClosedMonth", this.options).pipe(catchError(this.utilsService.handleError));
  }

  getDemandInvoiceSummary(packYear: number, year: number, month: number): Observable<any> {
    return this.http.get<IInvoiceOrderSummary[]>(environment.backendApiUrl + "/invoice/totalDemandInvoices?packYear="+ packYear + "&year=" + year + "&month=" + month).pipe(catchError(this.utilsService.handleError));
  }

  getDemandInvoice(packYear: number, year: number, month: number): Observable<any> {
    return this.http.get<IInvoiceDemands[]>(environment.backendApiUrl + "/invoice/demandInvoices?packYear="+ packYear + "&year=" + year + "&month=" + month, this.options).pipe(catchError(this.utilsService.handleError));
  }


  getNettingAccountsFromDemandNumber(dNumber: string): Observable<INetting[]> {
    return this.http.get<INetting[]>(environment.backendApiUrl + "/nettings/project/" + dNumber, this.options)
      .pipe(catchError(this.utilsService.handleError));
  }

  createNettingPack(np: INettingPack): Observable<INettingPack> {
    return this.http.post<INettingPack>(environment.backendApiUrl + "/nettingPack", np, this.options).pipe(catchError(this.utilsService.handleError));
  }

  getNettingFromDemand(demandNumber: string): Observable<INetting> {
    return this.http.get<INetting>(environment.backendApiUrl + "/nettingDemand/" + demandNumber, this.options)
      .pipe(catchError(this.utilsService.handleError));
  }

  exportPreparationNetting(packYear: number, year: number, month: number, excluded: string[], validation: boolean): Observable<any> {
    return this.http.get(environment.backendApiUrl + "/invoice/exportPreparationNetting?packYear="+ packYear + "&year=" + year + "&month=" + month
      + "&excludedList=" + excluded + "&validation=" + validation, {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }
 
  getCalculatedNetting(packYear: number, year: number, month: string, sequence: number, nettingSource: INettingWithMontant[]): Observable<ICalculatedNetting[]> {
    return this.http.post<ICalculatedNetting[]>(environment.backendApiUrl + "/netting/calculation/" + packYear + "/"+ year + "/" + month + "/" + sequence+ "/all" , nettingSource, this.options).pipe(catchError(this.utilsService.handleError));
  }

  getNettingNamesWithCost(yearPack: string, year: string ,month: string): Observable<INettingWithMontant[]> {
    return this.http.get<INettingWithMontant[]>(environment.backendApiUrl + "/nettings/nameswithcost/" + yearPack + "/" + year + "/" + month, this.options).pipe(catchError(this.utilsService.handleError));
  }

  getNettingCA(yearPack: string, yearFac: string, monthFac: string): Observable<number> {
    return this.http.get<number>(environment.backendApiUrl + "/nettings/CA/" + yearPack + "/" + yearFac+ "/" + monthFac, this.options).pipe(catchError(this.utilsService.handleError));
  }

  getNettingCABySchema(yearPack: string, yearFac: string, monthFac: string): Observable<INettingSchemaMontant[]> {
    return this.http.get<INettingSchemaMontant[]>(environment.backendApiUrl + "/nettings/CA/schema/" + yearPack + "/" + yearFac+ "/" + monthFac, this.options).pipe(catchError(this.utilsService.handleError));
  }

  exportNettingCalculated(nettingCalculatedSource: ICalculatedNetting[], date: string): Observable<any> {
    return this.http.post(environment.backendApiUrl + "/netting/calculation/export/" + date, nettingCalculatedSource, {responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
  }

}
