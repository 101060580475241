import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { INettingSchema } from 'src/app/shared/models/netting-schema';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import { IFullNetting } from 'src/app/shared/models/calculated_netting';



@Injectable({
    providedIn: 'root'
  })

export class NettingSchemaService {



    options = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
    };


    constructor(private http: HttpClient, private utilsService:UtilsService) {

    }

    createNettingSchema(n: INettingSchema): Observable<INettingSchema> {
        return this.http.post<INettingSchema>(environment.backendApiUrl + "/nettings-schemas" , n, this.options).pipe(catchError(this.utilsService.handleError));
    }

    updateNettingSchema(id: string, n: INettingSchema): Observable<INettingSchema> {
        return this.http.put<INettingSchema>(environment.backendApiUrl + "/nettings-schemas/" + id, n, this.options).pipe(catchError(this.utilsService.handleError));
    }

    deleteNettingSchema(n: INettingSchema): Observable<any> {
        return this.http.delete<INettingSchema>(environment.backendApiUrl + "/nettings-schemas/" + n.id, this.options).pipe(catchError(this.utilsService.handleError));
    }

    exportDataNettingSchema(): Observable<any> {
        return this.http.get(environment.backendApiUrl + "/nettings-schemas/export", { responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
    }

    getNettingSchemas(): Observable<INettingSchema[]> {
        return this.http.get<INettingSchema[]>(environment.backendApiUrl + "/nettings-schemaswithNA", this.options).pipe(catchError(this.utilsService.handleError));
    }

    getSchemasNames(): Observable<string[]> {
        return this.http.get<string[]>(environment.backendApiUrl + "/nettings/schemas-names", this.options).pipe(catchError(this.utilsService.handleError));
    }

    getFullNetting(year:string, month:string, schema:string): Observable<IFullNetting[]> {
        return this.http.get<IFullNetting[]>(environment.backendApiUrl + "/netting/full/" + year + "/" + month + "/" + schema, this.options).pipe(catchError(this.utilsService.handleError));
    }

    exportNettingView(nettingCalculatedSource: any[], headers: any[]): Observable<any> {
        return this.http.post(environment.backendApiUrl + "/netting/view/export?headers=" + headers + "", nettingCalculatedSource , { responseType: 'blob'}).pipe(catchError(this.utilsService.handleError));
    }

}
