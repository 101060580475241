<div class="operation">

  <div id="topSection-vue_netting">

    <div>
      <mat-form-field appearance="outline">
        <mat-label>Année du pack</mat-label>
        <mat-select [(ngModel)]="packSelected" (selectionChange)="changePackYear($event.value)">
          <mat-option *ngFor="let packYear of packYears" [value]="packYear">{{ packYear }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!isPackFusioned()">
      <mat-form-field appearance="outline" >
        <mat-label>Année de facturation</mat-label>
        <mat-select [(ngModel)]="yearSelected" (selectionChange)="changeYear($event.value)" >
          <mat-option *ngFor="let year of years" [value]="year" [disabled]="isYearDisabled(year)">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div> Mois d'arrêté: {{ monthSelected }}/{{ isPackFusioned() ? packSelected : yearSelected }}</div>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>Schéma:</mat-label>
        <mat-select [(ngModel)]="schemaSelected" (selectionChange)="changeSchema($event.value)">
          <mat-option *ngFor="let schema of schemas" [value]="schema">{{ schema }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <button mat-raised-button class="indigo " [style.background-color]="theme.getColor('Main Dark')"
              type="submit" (click)="exportNetting()">
        <mat-icon>get_app</mat-icon>
        Exporter le résultat
      </button>
    </div>
  </div>

  <hr class="hr">

  <div id="pageSection-vue_netting">
    <mat-table class="light mat-elevation-z4" [dataSource]="nettingSource">
      <ng-container *ngFor="let dispCol of displayedColumnsGen" matColumnDef="{{dispCol.key}}">
        <mat-header-cell *matHeaderCellDef>{{ dispCol.header }}</mat-header-cell>
        <mat-cell *matCellDef="let element" [class.Cell_rouge]="CellIsRed(element[dispCol.key], dispCol.header)">
          <ng-container *ngIf="isCurrencyColumn(dispCol.key); else defaultCell">
            {{ element[dispCol.key] | currency:'EUR':'symbol':'1.2-2' }}
          </ng-container>
          <ng-template #defaultCell>
            {{ element[dispCol.key] }}
          </ng-template>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumnsGenKeys;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsGenKeys"
      [class.row-fact-cds]="isFacturationCDSLine(row)"
      [class.row-new-project]="isNewProject(row)"></mat-row>
    </mat-table>
  </div>


</div>
