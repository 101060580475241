import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IMonthsClos } from 'src/app/shared/models/calendrier';
import { CalendrierService } from '../../calendrier-pack/calendrier.service';
import { NettingSchemaService } from '../../netting-schema/nettingschema.service';
import { IFullNetting } from 'src/app/shared/models/calculated_netting';
import * as fileSaver from 'file-saver';
import { ApplicationSettingsService } from '../../application-settings/application-settings.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ApplicationSettings } from 'src/app/shared/models/application-settings';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-facturation-vue_netting',
  templateUrl: './facturation-vue_netting.component.html',
  styleUrls: ['./facturation-vue_netting.component.less'],
})
export class FacturationVueNettingComponent implements OnInit {
  public years: number[] = [];
  public packYears: number[] = [];
  public yearSelected!: number;
  public packSelected!: number;
  public lastMonthClos!: number;
  public firstMonthClos!: number;
  public monthsClosByYear: IMonthsClos[] = [];
  public monthSelected!: string;
  public currentYear: number = new Date().getFullYear();
  public schemas: string[] = [];
  public schemaSelected!: string;
  public displayedColumnsGenKeys!: string[];
  public displayedColumnsGen!: Array<any>;
  public months_list: string[] = [
    'janvier',
    'fevrier',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aout',
    'septembre',
    'octobre',
    'novembre',
    'decembre',
  ];
  public nettingSource: IFullNetting[] = [];
  public currentYearPack!: number;
  private previousProject: string | null = null;
  private isOddProject: boolean = false;

  constructor(
    public _calendrierService: CalendrierService,
    public theme: ThemeService,
    private readonly _nss: NettingSchemaService,
    private readonly _ass: ApplicationSettingsService,
    private readonly snackbarService: SnackbarService,
    private cdr: ChangeDetectorRef
  ) {}

  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  exportNetting() {
    const headers: string[] = [];
    const dataTable: string[][] = [];

    this.displayedColumnsGen.forEach((col) => {
      headers.push(col.header);
    });

    this.nettingSource.forEach((row) => {
      const tempRow: string[] = [];

      for (let i = 0; i < this.displayedColumnsGenKeys.length; i++) {
        switch (i) {
          case 0:
            tempRow.push(row.projet + '');
            break;
          case 1:
            tempRow.push(row.nature + '');
            break;
          case 2:
            tempRow.push(row.intitule + '');
            break;
          case 3:
            tempRow.push(row.janvier + '');
            break;
          case 4:
            tempRow.push(row.fevrier + '');
            break;
          case 5:
            tempRow.push(row.mars + '');
            break;
          case 6:
            tempRow.push(row.avril + '');
            break;
          case 7:
            tempRow.push(row.mai + '');
            break;
          case 8:
            tempRow.push(row.juin + '');
            break;
          case 9:
            tempRow.push(row.juillet + '');
            break;
          case 10:
            tempRow.push(row.aout + '');
            break;
          case 11:
            tempRow.push(row.septembre + '');
            break;
          case 12:
            tempRow.push(row.octobre + '');
            break;
          case 13:
            tempRow.push(row.novembre + '');
            break;
          case 14:
            tempRow.push(row.decembre + '');
            break;
        }
      }

      dataTable.push(tempRow);
    });

    this._nss.exportNettingView(dataTable, headers).subscribe({
      next: (data) => {
        const blob: any = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(
          blob,
          'Vue netting -' +
            this._ass.applicationSettings.CDS_name +
            ' - ' +
            new Date().toLocaleDateString(),
        );
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar("Erreur lors de l'export");
      },
    });
  }

  public isPackFusioned(): boolean {
    const applicationSettings: ApplicationSettings =
      this._ass.getApplicationSettings();
    return applicationSettings.display_fusion_pack;
  }

  ngOnInit(): void {
    this.displayYear();
    this.getMonthClosService();
    this.getSchemas();
  }


  isYearDisabled(year: number): boolean {
    if (this.packSelected === this.currentYear - 1) {
      return year === this.currentYear + 1;
    } else if (this.packSelected === this.currentYear) {
      return year === this.currentYear - 1;
    }
    return false;
  }

  displayYear(): void {
    this.currentYearPack = this._ass.getApplicationSettings().Pack_current_year;
    this.years = [this.currentYear - 1, this.currentYear, this.currentYear + 1];
    this.packYears = [this.currentYear, this.currentYear - 1];
    this.yearSelected = this.currentYear;
    this.packSelected = this.currentYear;
  }

  getMonthClosService(): void {
    this._calendrierService.getMonthsClos(this.packSelected).subscribe({
      next: (data) => {
        if (data) {
          this.monthsClosByYear = data;
          this.firstMonthClos = this.monthsClosByYear[0].month;
          this.lastMonthClos =
            this.monthsClosByYear[this.monthsClosByYear.length - 1].month;
          this.month_to_string(this.lastMonthClos);
          this.updateTableColumns();
          this.getNetting();
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar(
          'Erreur lors de la recuperation du dernier mois clos',
        );
      },
    });
  }

  month_to_string(month: number): void {
    this.monthSelected = month.toString();
    if (this.monthSelected.length == 1) {
      this.monthSelected = '0' + this.monthSelected;
    }
  }

  changeYear(year: number): void {
    if (this.isPackFusioned()) {
      this.yearSelected = this.packSelected;
    } else {
      this.yearSelected = year;
    }
    this.getMonthClosService();
    this.getNetting();
  }

  changePackYear(packYear: number): void {
    this.packSelected = packYear;
    if (this.isPackFusioned()) {
      this.yearSelected = this.packSelected;
    }
    this.getMonthClosService();
    this.getNetting();
  }

  getSchemas(): void {
    this._nss.getSchemasNames().subscribe({
      next: (data) => {
        if (data) {
          this.schemas = data;
          this.schemas.splice(0, 0, 'CONSOLIDATION');
          this.schemas = this.schemas.filter((schema) => !schema.includes('GLOBAL'));
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar('Erreur lors de la recuperation des schemas');
      },
    });
  }

  changeSchema(schema: string): void {
    this.schemaSelected = schema;
    this.tableDefGenNet();
    this.getNetting();
  }

  getNetting(): void {
    this._nss
      .getFullNetting(
        this.packSelected.toString(),
        this.yearSelected.toString(),
        this.monthSelected,
        this.schemaSelected,
      )
      .subscribe({
        next: (data) => {
          if (data) {
            this.nettingSource = data;
          
          }
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar('Erreur lors de la recuperation des données');
        },
      });
  }

  updateTableColumns(): void {
    this.displayedColumnsGen = [
      {
        key: 'projet',
        header: 'Projet',
      },
      {
        key: 'nature',
        header: 'Nature imputation',
      },
      {
        key: 'intitule',
        header: 'Intitulé',
      },
    ];
    for (let i = this.firstMonthClos - 1; i < this.lastMonthClos; i++) {
      const header_month = this.months_list[i] + '-' + this.yearSelected;
      this.displayedColumnsGen.push({
        key: this.months_list[i],
        header: header_month,
      });
    }
    this.displayedColumnsGenKeys = this.displayedColumnsGen.map(
      (col) => col.key,
    );
  }

  tableDefGenNet(): void {
    this.updateTableColumns();
  }

  CellIsRed(element: any, header: string): boolean {
    const headerGen: string[] = ['Projet', 'Nature imputation', 'Intitulé'];
    if (!headerGen.includes(header)) {
      if (element < 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isCurrencyColumn(columnKey: string): boolean {
    const currencyColumns = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'];
    return currencyColumns.includes(columnKey);
  }


   
  isFacturationCDSLine(row: any): boolean {
    return row.projet === 'FACTURATION CDS';
  }

  isNewProject(row: any): boolean {
    return row.projet != null;
  }

}